import { default as _91type_93_45dashboardrV1ErbsZaIMeta } from "/opt/atlassian/pipelines/agent/build/pages/[type]-dashboard.vue?macro=true";
import { default as _91uniqueName_933Lm4wPXcsHMeta } from "/opt/atlassian/pipelines/agent/build/pages/[uniqueName].vue?macro=true";
import { default as indexapG7pWJEFVMeta } from "/opt/atlassian/pipelines/agent/build/pages/apply-as-industry-pro/index.vue?macro=true";
import { default as successXrjgeVaxdmMeta } from "/opt/atlassian/pipelines/agent/build/pages/apply-as-industry-pro/success.vue?macro=true";
import { default as _91uniqueName_93BwTmkz6FkmMeta } from "/opt/atlassian/pipelines/agent/build/pages/artist/[uniqueName].vue?macro=true";
import { default as _91orderId_93zllofqFMMsMeta } from "/opt/atlassian/pipelines/agent/build/pages/call/[orderId].vue?macro=true";
import { default as callsR7bZlaycTeMeta } from "/opt/atlassian/pipelines/agent/build/pages/calls.vue?macro=true";
import { default as chatLXQrzrLwFXMeta } from "/opt/atlassian/pipelines/agent/build/pages/chat.vue?macro=true";
import { default as dashboardaGDr6YjHlOMeta } from "/opt/atlassian/pipelines/agent/build/pages/dashboard.vue?macro=true";
import { default as demo_45submissions_45oldoQs5UkxFPCMeta } from "/opt/atlassian/pipelines/agent/build/pages/demo-submissions-old.vue?macro=true";
import { default as discover966OP7S1wpMeta } from "/opt/atlassian/pipelines/agent/build/pages/discover.vue?macro=true";
import { default as feedbackVMugCi3nRHMeta } from "/opt/atlassian/pipelines/agent/build/pages/feedback.vue?macro=true";
import { default as impersonateb95dvDZd82Meta } from "/opt/atlassian/pipelines/agent/build/pages/impersonate.vue?macro=true";
import { default as index_45oldsAhatwLnOCMeta } from "/opt/atlassian/pipelines/agent/build/pages/index-old.vue?macro=true";
import { default as indexOD9t3F2bSJMeta } from "/opt/atlassian/pipelines/agent/build/pages/index.vue?macro=true";
import { default as _91_46_46_46slugs_93pYAq9LylIxMeta } from "/opt/atlassian/pipelines/agent/build/pages/industry-pros/[...slugs].vue?macro=true";
import { default as indexjOUgwMK6flMeta } from "/opt/atlassian/pipelines/agent/build/pages/industry-pros/index.vue?macro=true";
import { default as logineaOYVOrhPDMeta } from "/opt/atlassian/pipelines/agent/build/pages/login.vue?macro=true";
import { default as music46RDly6mjiMeta } from "/opt/atlassian/pipelines/agent/build/pages/music.vue?macro=true";
import { default as my_45_91type_93_45profilepXZNI8exKWMeta } from "/opt/atlassian/pipelines/agent/build/pages/my-[type]-profile.vue?macro=true";
import { default as _91opportunitySlug_93OaTvUwvQDKMeta } from "/opt/atlassian/pipelines/agent/build/pages/my-opportunities-old/[opportunitySlug].vue?macro=true";
import { default as _91opportunitySubmissionId_93pf6KeTHB6FMeta } from "/opt/atlassian/pipelines/agent/build/pages/my-opportunities-old/[opportunitySlug]/[opportunitySubmissionId].vue?macro=true";
import { default as indexiWf25dcnA8Meta } from "/opt/atlassian/pipelines/agent/build/pages/my-opportunities-old/index.vue?macro=true";
import { default as _91opportunitySlug_93z0jn7Mq9fAMeta } from "/opt/atlassian/pipelines/agent/build/pages/my-opportunities/[opportunitySlug].vue?macro=true";
import { default as indexOYELuD4HY7Meta } from "/opt/atlassian/pipelines/agent/build/pages/my-opportunities/index.vue?macro=true";
import { default as _91opportunityId_93qu3nmpYMvGMeta } from "/opt/atlassian/pipelines/agent/build/pages/opportunity/[opportunityId].vue?macro=true";
import { default as profilegoEACv8cisMeta } from "/opt/atlassian/pipelines/agent/build/pages/opportunity/[opportunityId]/profile.vue?macro=true";
import { default as questionOY79R0RlGbMeta } from "/opt/atlassian/pipelines/agent/build/pages/opportunity/[opportunityId]/question.vue?macro=true";
import { default as select_45trackKoWBgEG17uMeta } from "/opt/atlassian/pipelines/agent/build/pages/opportunity/[opportunityId]/select-track.vue?macro=true";
import { default as success8eOgYH62EiMeta } from "/opt/atlassian/pipelines/agent/build/pages/opportunity/success.vue?macro=true";
import { default as post_45onboardingCJH2Yzw2VFMeta } from "/opt/atlassian/pipelines/agent/build/pages/post-onboarding.vue?macro=true";
import { default as _91uniqueId_93fLpX17FVIUMeta } from "/opt/atlassian/pipelines/agent/build/pages/pro-onboarding/[uniqueId].vue?macro=true";
import { default as errorIv5XYqoGn9Meta } from "/opt/atlassian/pipelines/agent/build/pages/pro-onboarding/error.vue?macro=true";
import { default as _91uniqueName_935ZEjSbJaoBMeta } from "/opt/atlassian/pipelines/agent/build/pages/purchase/[uniqueName].vue?macro=true";
import { default as checkoutSAAIaoGS6pMeta } from "/opt/atlassian/pipelines/agent/build/pages/purchase/checkout.vue?macro=true";
import { default as about_45yourselfqecq8nYLvWMeta } from "/opt/atlassian/pipelines/agent/build/pages/purchase/checkout/about-yourself.vue?macro=true";
import { default as extra_45contentCivRcH08vuMeta } from "/opt/atlassian/pipelines/agent/build/pages/purchase/checkout/extra-content.vue?macro=true";
import { default as feedback_45topichcdoCp2TooMeta } from "/opt/atlassian/pipelines/agent/build/pages/purchase/checkout/feedback-topic.vue?macro=true";
import { default as payWv2aCCK3O6Meta } from "/opt/atlassian/pipelines/agent/build/pages/purchase/checkout/pay.vue?macro=true";
import { default as phone_45numberjgiCWDUMfYMeta } from "/opt/atlassian/pipelines/agent/build/pages/purchase/checkout/phone-number.vue?macro=true";
import { default as pick_45time1GxyUOeXBkMeta } from "/opt/atlassian/pipelines/agent/build/pages/purchase/checkout/pick-time.vue?macro=true";
import { default as reviewkwFRsvNxKVMeta } from "/opt/atlassian/pipelines/agent/build/pages/purchase/checkout/review.vue?macro=true";
import { default as select_45stageigiTf5GFSMMeta } from "/opt/atlassian/pipelines/agent/build/pages/purchase/checkout/select-stage.vue?macro=true";
import { default as select_45trackoz5fpR50wvMeta } from "/opt/atlassian/pipelines/agent/build/pages/purchase/checkout/select-track.vue?macro=true";
import { default as successXn8P4AGG2wMeta } from "/opt/atlassian/pipelines/agent/build/pages/purchase/success.vue?macro=true";
import { default as _91requestId_93368OTpD3QWMeta } from "/opt/atlassian/pipelines/agent/build/pages/rating/[userId]/[requestId].vue?macro=true";
import { default as signupnmDFzkRJBDMeta } from "/opt/atlassian/pipelines/agent/build/pages/signup.vue?macro=true";
import { default as indexfAUEfKWlfkMeta } from "/opt/atlassian/pipelines/agent/build/pages/submit/index.vue?macro=true";
import { default as profilenfJizJu1TBMeta } from "/opt/atlassian/pipelines/agent/build/pages/submit/profile.vue?macro=true";
import { default as successbvxNomgpPxMeta } from "/opt/atlassian/pipelines/agent/build/pages/submit/success.vue?macro=true";
import { default as trackwNagwPaqqEMeta } from "/opt/atlassian/pipelines/agent/build/pages/submit/track.vue?macro=true";
import { default as wishlistWWXfInC2n9Meta } from "/opt/atlassian/pipelines/agent/build/pages/wishlist.vue?macro=true";
import { default as xposure_45playlistsM2tx1wx64UMeta } from "/opt/atlassian/pipelines/agent/build/pages/xposure-playlists.vue?macro=true";
export default [
  {
    name: _91type_93_45dashboardrV1ErbsZaIMeta?.name ?? "type-dashboard",
    path: _91type_93_45dashboardrV1ErbsZaIMeta?.path ?? "/:type-dashboard",
    children: [],
    meta: _91type_93_45dashboardrV1ErbsZaIMeta || {},
    alias: _91type_93_45dashboardrV1ErbsZaIMeta?.alias || [],
    redirect: _91type_93_45dashboardrV1ErbsZaIMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/[type]-dashboard.vue").then(m => m.default || m)
  },
  {
    name: _91uniqueName_933Lm4wPXcsHMeta?.name ?? "uniqueName",
    path: _91uniqueName_933Lm4wPXcsHMeta?.path ?? "/:uniqueName",
    children: [],
    meta: _91uniqueName_933Lm4wPXcsHMeta || {},
    alias: _91uniqueName_933Lm4wPXcsHMeta?.alias || [],
    redirect: _91uniqueName_933Lm4wPXcsHMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/[uniqueName].vue").then(m => m.default || m)
  },
  {
    name: indexapG7pWJEFVMeta?.name ?? "apply-as-industry-pro",
    path: indexapG7pWJEFVMeta?.path ?? "/apply-as-industry-pro",
    children: [],
    meta: indexapG7pWJEFVMeta || {},
    alias: indexapG7pWJEFVMeta?.alias || [],
    redirect: indexapG7pWJEFVMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/apply-as-industry-pro/index.vue").then(m => m.default || m)
  },
  {
    name: successXrjgeVaxdmMeta?.name ?? "apply-as-industry-pro-success",
    path: successXrjgeVaxdmMeta?.path ?? "/apply-as-industry-pro/success",
    children: [],
    meta: successXrjgeVaxdmMeta || {},
    alias: successXrjgeVaxdmMeta?.alias || [],
    redirect: successXrjgeVaxdmMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/apply-as-industry-pro/success.vue").then(m => m.default || m)
  },
  {
    name: _91uniqueName_93BwTmkz6FkmMeta?.name ?? "artist-uniqueName",
    path: _91uniqueName_93BwTmkz6FkmMeta?.path ?? "/artist/:uniqueName",
    children: [],
    meta: _91uniqueName_93BwTmkz6FkmMeta || {},
    alias: _91uniqueName_93BwTmkz6FkmMeta?.alias || [],
    redirect: _91uniqueName_93BwTmkz6FkmMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/artist/[uniqueName].vue").then(m => m.default || m)
  },
  {
    name: _91orderId_93zllofqFMMsMeta?.name ?? "call-orderId",
    path: _91orderId_93zllofqFMMsMeta?.path ?? "/call/:orderId",
    children: [],
    meta: _91orderId_93zllofqFMMsMeta || {},
    alias: _91orderId_93zllofqFMMsMeta?.alias || [],
    redirect: _91orderId_93zllofqFMMsMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/call/[orderId].vue").then(m => m.default || m)
  },
  {
    name: callsR7bZlaycTeMeta?.name ?? "calls",
    path: callsR7bZlaycTeMeta?.path ?? "/calls",
    children: [],
    meta: callsR7bZlaycTeMeta || {},
    alias: callsR7bZlaycTeMeta?.alias || [],
    redirect: callsR7bZlaycTeMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/calls.vue").then(m => m.default || m)
  },
  {
    name: chatLXQrzrLwFXMeta?.name ?? "chat",
    path: chatLXQrzrLwFXMeta?.path ?? "/chat",
    children: [],
    meta: chatLXQrzrLwFXMeta || {},
    alias: chatLXQrzrLwFXMeta?.alias || [],
    redirect: chatLXQrzrLwFXMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/chat.vue").then(m => m.default || m)
  },
  {
    name: dashboardaGDr6YjHlOMeta?.name ?? "dashboard",
    path: dashboardaGDr6YjHlOMeta?.path ?? "/dashboard",
    children: [],
    meta: dashboardaGDr6YjHlOMeta || {},
    alias: dashboardaGDr6YjHlOMeta?.alias || [],
    redirect: dashboardaGDr6YjHlOMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/dashboard.vue").then(m => m.default || m)
  },
  {
    name: demo_45submissions_45oldoQs5UkxFPCMeta?.name ?? "demo-submissions-old",
    path: demo_45submissions_45oldoQs5UkxFPCMeta?.path ?? "/demo-submissions-old",
    children: [],
    meta: demo_45submissions_45oldoQs5UkxFPCMeta || {},
    alias: demo_45submissions_45oldoQs5UkxFPCMeta?.alias || [],
    redirect: demo_45submissions_45oldoQs5UkxFPCMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/demo-submissions-old.vue").then(m => m.default || m)
  },
  {
    name: discover966OP7S1wpMeta?.name ?? "discover",
    path: discover966OP7S1wpMeta?.path ?? "/discover",
    children: [],
    meta: discover966OP7S1wpMeta || {},
    alias: discover966OP7S1wpMeta?.alias || [],
    redirect: discover966OP7S1wpMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/discover.vue").then(m => m.default || m)
  },
  {
    name: feedbackVMugCi3nRHMeta?.name ?? "feedback",
    path: feedbackVMugCi3nRHMeta?.path ?? "/feedback",
    children: [],
    meta: feedbackVMugCi3nRHMeta || {},
    alias: feedbackVMugCi3nRHMeta?.alias || [],
    redirect: feedbackVMugCi3nRHMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/feedback.vue").then(m => m.default || m)
  },
  {
    name: impersonateb95dvDZd82Meta?.name ?? "impersonate",
    path: impersonateb95dvDZd82Meta?.path ?? "/impersonate",
    children: [],
    meta: impersonateb95dvDZd82Meta || {},
    alias: impersonateb95dvDZd82Meta?.alias || [],
    redirect: impersonateb95dvDZd82Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/impersonate.vue").then(m => m.default || m)
  },
  {
    name: index_45oldsAhatwLnOCMeta?.name ?? "index-old",
    path: index_45oldsAhatwLnOCMeta?.path ?? "/index-old",
    children: [],
    meta: index_45oldsAhatwLnOCMeta || {},
    alias: index_45oldsAhatwLnOCMeta?.alias || [],
    redirect: index_45oldsAhatwLnOCMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/index-old.vue").then(m => m.default || m)
  },
  {
    name: indexOD9t3F2bSJMeta?.name ?? "index",
    path: indexOD9t3F2bSJMeta?.path ?? "/",
    children: [],
    meta: indexOD9t3F2bSJMeta || {},
    alias: indexOD9t3F2bSJMeta?.alias || [],
    redirect: indexOD9t3F2bSJMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slugs_93pYAq9LylIxMeta?.name ?? "industry-pros-slugs",
    path: _91_46_46_46slugs_93pYAq9LylIxMeta?.path ?? "/industry-pros/:slugs(.*)*",
    children: [],
    meta: _91_46_46_46slugs_93pYAq9LylIxMeta || {},
    alias: _91_46_46_46slugs_93pYAq9LylIxMeta?.alias || [],
    redirect: _91_46_46_46slugs_93pYAq9LylIxMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/industry-pros/[...slugs].vue").then(m => m.default || m)
  },
  {
    name: indexjOUgwMK6flMeta?.name ?? "industry-pros",
    path: indexjOUgwMK6flMeta?.path ?? "/industry-pros",
    children: [],
    meta: indexjOUgwMK6flMeta || {},
    alias: indexjOUgwMK6flMeta?.alias || [],
    redirect: indexjOUgwMK6flMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/industry-pros/index.vue").then(m => m.default || m)
  },
  {
    name: logineaOYVOrhPDMeta?.name ?? "login",
    path: logineaOYVOrhPDMeta?.path ?? "/login",
    children: [],
    meta: logineaOYVOrhPDMeta || {},
    alias: logineaOYVOrhPDMeta?.alias || [],
    redirect: logineaOYVOrhPDMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/login.vue").then(m => m.default || m)
  },
  {
    name: music46RDly6mjiMeta?.name ?? "music",
    path: music46RDly6mjiMeta?.path ?? "/music",
    children: [],
    meta: music46RDly6mjiMeta || {},
    alias: music46RDly6mjiMeta?.alias || [],
    redirect: music46RDly6mjiMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/music.vue").then(m => m.default || m)
  },
  {
    name: my_45_91type_93_45profilepXZNI8exKWMeta?.name ?? "my-type-profile",
    path: my_45_91type_93_45profilepXZNI8exKWMeta?.path ?? "/my-:type-profile",
    children: [],
    meta: my_45_91type_93_45profilepXZNI8exKWMeta || {},
    alias: my_45_91type_93_45profilepXZNI8exKWMeta?.alias || [],
    redirect: my_45_91type_93_45profilepXZNI8exKWMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/my-[type]-profile.vue").then(m => m.default || m)
  },
  {
    name: _91opportunitySlug_93OaTvUwvQDKMeta?.name ?? "my-opportunities-old-opportunitySlug",
    path: _91opportunitySlug_93OaTvUwvQDKMeta?.path ?? "/my-opportunities-old/:opportunitySlug",
    children: [
  {
    name: _91opportunitySubmissionId_93pf6KeTHB6FMeta?.name ?? "my-opportunities-old-opportunitySlug-opportunitySubmissionId",
    path: _91opportunitySubmissionId_93pf6KeTHB6FMeta?.path ?? ":opportunitySubmissionId",
    children: [],
    meta: _91opportunitySubmissionId_93pf6KeTHB6FMeta || {},
    alias: _91opportunitySubmissionId_93pf6KeTHB6FMeta?.alias || [],
    redirect: _91opportunitySubmissionId_93pf6KeTHB6FMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/my-opportunities-old/[opportunitySlug]/[opportunitySubmissionId].vue").then(m => m.default || m)
  }
],
    meta: _91opportunitySlug_93OaTvUwvQDKMeta || {},
    alias: _91opportunitySlug_93OaTvUwvQDKMeta?.alias || [],
    redirect: _91opportunitySlug_93OaTvUwvQDKMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/my-opportunities-old/[opportunitySlug].vue").then(m => m.default || m)
  },
  {
    name: indexiWf25dcnA8Meta?.name ?? "my-opportunities-old",
    path: indexiWf25dcnA8Meta?.path ?? "/my-opportunities-old",
    children: [],
    meta: indexiWf25dcnA8Meta || {},
    alias: indexiWf25dcnA8Meta?.alias || [],
    redirect: indexiWf25dcnA8Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/my-opportunities-old/index.vue").then(m => m.default || m)
  },
  {
    name: _91opportunitySlug_93z0jn7Mq9fAMeta?.name ?? "my-opportunities-opportunitySlug",
    path: _91opportunitySlug_93z0jn7Mq9fAMeta?.path ?? "/my-opportunities/:opportunitySlug",
    children: [],
    meta: _91opportunitySlug_93z0jn7Mq9fAMeta || {},
    alias: _91opportunitySlug_93z0jn7Mq9fAMeta?.alias || [],
    redirect: _91opportunitySlug_93z0jn7Mq9fAMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/my-opportunities/[opportunitySlug].vue").then(m => m.default || m)
  },
  {
    name: indexOYELuD4HY7Meta?.name ?? "my-opportunities",
    path: indexOYELuD4HY7Meta?.path ?? "/my-opportunities",
    children: [],
    meta: indexOYELuD4HY7Meta || {},
    alias: indexOYELuD4HY7Meta?.alias || [],
    redirect: indexOYELuD4HY7Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/my-opportunities/index.vue").then(m => m.default || m)
  },
  {
    name: _91opportunityId_93qu3nmpYMvGMeta?.name ?? "opportunity-opportunityId",
    path: _91opportunityId_93qu3nmpYMvGMeta?.path ?? "/opportunity/:opportunityId",
    children: [
  {
    name: profilegoEACv8cisMeta?.name ?? "opportunity-opportunityId-profile",
    path: profilegoEACv8cisMeta?.path ?? "profile",
    children: [],
    meta: profilegoEACv8cisMeta || {},
    alias: profilegoEACv8cisMeta?.alias || [],
    redirect: profilegoEACv8cisMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/opportunity/[opportunityId]/profile.vue").then(m => m.default || m)
  },
  {
    name: questionOY79R0RlGbMeta?.name ?? "opportunity-opportunityId-question",
    path: questionOY79R0RlGbMeta?.path ?? "question",
    children: [],
    meta: questionOY79R0RlGbMeta || {},
    alias: questionOY79R0RlGbMeta?.alias || [],
    redirect: questionOY79R0RlGbMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/opportunity/[opportunityId]/question.vue").then(m => m.default || m)
  },
  {
    name: select_45trackKoWBgEG17uMeta?.name ?? "opportunity-opportunityId-select-track",
    path: select_45trackKoWBgEG17uMeta?.path ?? "select-track",
    children: [],
    meta: select_45trackKoWBgEG17uMeta || {},
    alias: select_45trackKoWBgEG17uMeta?.alias || [],
    redirect: select_45trackKoWBgEG17uMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/opportunity/[opportunityId]/select-track.vue").then(m => m.default || m)
  }
],
    meta: _91opportunityId_93qu3nmpYMvGMeta || {},
    alias: _91opportunityId_93qu3nmpYMvGMeta?.alias || [],
    redirect: _91opportunityId_93qu3nmpYMvGMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/opportunity/[opportunityId].vue").then(m => m.default || m)
  },
  {
    name: success8eOgYH62EiMeta?.name ?? "opportunity-success",
    path: success8eOgYH62EiMeta?.path ?? "/opportunity/success",
    children: [],
    meta: success8eOgYH62EiMeta || {},
    alias: success8eOgYH62EiMeta?.alias || [],
    redirect: success8eOgYH62EiMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/opportunity/success.vue").then(m => m.default || m)
  },
  {
    name: post_45onboardingCJH2Yzw2VFMeta?.name ?? "post-onboarding",
    path: post_45onboardingCJH2Yzw2VFMeta?.path ?? "/post-onboarding",
    children: [],
    meta: post_45onboardingCJH2Yzw2VFMeta || {},
    alias: post_45onboardingCJH2Yzw2VFMeta?.alias || [],
    redirect: post_45onboardingCJH2Yzw2VFMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/post-onboarding.vue").then(m => m.default || m)
  },
  {
    name: _91uniqueId_93fLpX17FVIUMeta?.name ?? "pro-onboarding-uniqueId",
    path: _91uniqueId_93fLpX17FVIUMeta?.path ?? "/pro-onboarding/:uniqueId",
    children: [],
    meta: _91uniqueId_93fLpX17FVIUMeta || {},
    alias: _91uniqueId_93fLpX17FVIUMeta?.alias || [],
    redirect: _91uniqueId_93fLpX17FVIUMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/pro-onboarding/[uniqueId].vue").then(m => m.default || m)
  },
  {
    name: errorIv5XYqoGn9Meta?.name ?? "pro-onboarding-error",
    path: errorIv5XYqoGn9Meta?.path ?? "/pro-onboarding/error",
    children: [],
    meta: errorIv5XYqoGn9Meta || {},
    alias: errorIv5XYqoGn9Meta?.alias || [],
    redirect: errorIv5XYqoGn9Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/pro-onboarding/error.vue").then(m => m.default || m)
  },
  {
    name: _91uniqueName_935ZEjSbJaoBMeta?.name ?? "purchase-uniqueName",
    path: _91uniqueName_935ZEjSbJaoBMeta?.path ?? "/purchase/:uniqueName",
    children: [],
    meta: _91uniqueName_935ZEjSbJaoBMeta || {},
    alias: _91uniqueName_935ZEjSbJaoBMeta?.alias || [],
    redirect: _91uniqueName_935ZEjSbJaoBMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/purchase/[uniqueName].vue").then(m => m.default || m)
  },
  {
    name: checkoutSAAIaoGS6pMeta?.name ?? "purchase-checkout",
    path: checkoutSAAIaoGS6pMeta?.path ?? "/purchase/checkout",
    children: [
  {
    name: about_45yourselfqecq8nYLvWMeta?.name ?? "purchase-checkout-about-yourself",
    path: about_45yourselfqecq8nYLvWMeta?.path ?? "about-yourself",
    children: [],
    meta: about_45yourselfqecq8nYLvWMeta || {},
    alias: about_45yourselfqecq8nYLvWMeta?.alias || [],
    redirect: about_45yourselfqecq8nYLvWMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/purchase/checkout/about-yourself.vue").then(m => m.default || m)
  },
  {
    name: extra_45contentCivRcH08vuMeta?.name ?? "purchase-checkout-extra-content",
    path: extra_45contentCivRcH08vuMeta?.path ?? "extra-content",
    children: [],
    meta: extra_45contentCivRcH08vuMeta || {},
    alias: extra_45contentCivRcH08vuMeta?.alias || [],
    redirect: extra_45contentCivRcH08vuMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/purchase/checkout/extra-content.vue").then(m => m.default || m)
  },
  {
    name: feedback_45topichcdoCp2TooMeta?.name ?? "purchase-checkout-feedback-topic",
    path: feedback_45topichcdoCp2TooMeta?.path ?? "feedback-topic",
    children: [],
    meta: feedback_45topichcdoCp2TooMeta || {},
    alias: feedback_45topichcdoCp2TooMeta?.alias || [],
    redirect: feedback_45topichcdoCp2TooMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/purchase/checkout/feedback-topic.vue").then(m => m.default || m)
  },
  {
    name: payWv2aCCK3O6Meta?.name ?? "purchase-checkout-pay",
    path: payWv2aCCK3O6Meta?.path ?? "pay",
    children: [],
    meta: payWv2aCCK3O6Meta || {},
    alias: payWv2aCCK3O6Meta?.alias || [],
    redirect: payWv2aCCK3O6Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/purchase/checkout/pay.vue").then(m => m.default || m)
  },
  {
    name: phone_45numberjgiCWDUMfYMeta?.name ?? "purchase-checkout-phone-number",
    path: phone_45numberjgiCWDUMfYMeta?.path ?? "phone-number",
    children: [],
    meta: phone_45numberjgiCWDUMfYMeta || {},
    alias: phone_45numberjgiCWDUMfYMeta?.alias || [],
    redirect: phone_45numberjgiCWDUMfYMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/purchase/checkout/phone-number.vue").then(m => m.default || m)
  },
  {
    name: pick_45time1GxyUOeXBkMeta?.name ?? "purchase-checkout-pick-time",
    path: pick_45time1GxyUOeXBkMeta?.path ?? "pick-time",
    children: [],
    meta: pick_45time1GxyUOeXBkMeta || {},
    alias: pick_45time1GxyUOeXBkMeta?.alias || [],
    redirect: pick_45time1GxyUOeXBkMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/purchase/checkout/pick-time.vue").then(m => m.default || m)
  },
  {
    name: reviewkwFRsvNxKVMeta?.name ?? "purchase-checkout-review",
    path: reviewkwFRsvNxKVMeta?.path ?? "review",
    children: [],
    meta: reviewkwFRsvNxKVMeta || {},
    alias: reviewkwFRsvNxKVMeta?.alias || [],
    redirect: reviewkwFRsvNxKVMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/purchase/checkout/review.vue").then(m => m.default || m)
  },
  {
    name: select_45stageigiTf5GFSMMeta?.name ?? "purchase-checkout-select-stage",
    path: select_45stageigiTf5GFSMMeta?.path ?? "select-stage",
    children: [],
    meta: select_45stageigiTf5GFSMMeta || {},
    alias: select_45stageigiTf5GFSMMeta?.alias || [],
    redirect: select_45stageigiTf5GFSMMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/purchase/checkout/select-stage.vue").then(m => m.default || m)
  },
  {
    name: select_45trackoz5fpR50wvMeta?.name ?? "purchase-checkout-select-track",
    path: select_45trackoz5fpR50wvMeta?.path ?? "select-track",
    children: [],
    meta: select_45trackoz5fpR50wvMeta || {},
    alias: select_45trackoz5fpR50wvMeta?.alias || [],
    redirect: select_45trackoz5fpR50wvMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/purchase/checkout/select-track.vue").then(m => m.default || m)
  }
],
    meta: checkoutSAAIaoGS6pMeta || {},
    alias: checkoutSAAIaoGS6pMeta?.alias || [],
    redirect: checkoutSAAIaoGS6pMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/purchase/checkout.vue").then(m => m.default || m)
  },
  {
    name: successXn8P4AGG2wMeta?.name ?? "purchase-success",
    path: successXn8P4AGG2wMeta?.path ?? "/purchase/success",
    children: [],
    meta: successXn8P4AGG2wMeta || {},
    alias: successXn8P4AGG2wMeta?.alias || [],
    redirect: successXn8P4AGG2wMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/purchase/success.vue").then(m => m.default || m)
  },
  {
    name: _91requestId_93368OTpD3QWMeta?.name ?? "rating-userId-requestId",
    path: _91requestId_93368OTpD3QWMeta?.path ?? "/rating/:userId/:requestId",
    children: [],
    meta: _91requestId_93368OTpD3QWMeta || {},
    alias: _91requestId_93368OTpD3QWMeta?.alias || [],
    redirect: _91requestId_93368OTpD3QWMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/rating/[userId]/[requestId].vue").then(m => m.default || m)
  },
  {
    name: signupnmDFzkRJBDMeta?.name ?? "signup",
    path: signupnmDFzkRJBDMeta?.path ?? "/signup",
    children: [],
    meta: signupnmDFzkRJBDMeta || {},
    alias: signupnmDFzkRJBDMeta?.alias || [],
    redirect: signupnmDFzkRJBDMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/signup.vue").then(m => m.default || m)
  },
  {
    name: indexfAUEfKWlfkMeta?.name ?? "submit",
    path: indexfAUEfKWlfkMeta?.path ?? "/submit",
    children: [],
    meta: indexfAUEfKWlfkMeta || {},
    alias: indexfAUEfKWlfkMeta?.alias || [],
    redirect: indexfAUEfKWlfkMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/submit/index.vue").then(m => m.default || m)
  },
  {
    name: profilenfJizJu1TBMeta?.name ?? "submit-profile",
    path: profilenfJizJu1TBMeta?.path ?? "/submit/profile",
    children: [],
    meta: profilenfJizJu1TBMeta || {},
    alias: profilenfJizJu1TBMeta?.alias || [],
    redirect: profilenfJizJu1TBMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/submit/profile.vue").then(m => m.default || m)
  },
  {
    name: successbvxNomgpPxMeta?.name ?? "submit-success",
    path: successbvxNomgpPxMeta?.path ?? "/submit/success",
    children: [],
    meta: successbvxNomgpPxMeta || {},
    alias: successbvxNomgpPxMeta?.alias || [],
    redirect: successbvxNomgpPxMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/submit/success.vue").then(m => m.default || m)
  },
  {
    name: trackwNagwPaqqEMeta?.name ?? "submit-track",
    path: trackwNagwPaqqEMeta?.path ?? "/submit/track",
    children: [],
    meta: trackwNagwPaqqEMeta || {},
    alias: trackwNagwPaqqEMeta?.alias || [],
    redirect: trackwNagwPaqqEMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/submit/track.vue").then(m => m.default || m)
  },
  {
    name: wishlistWWXfInC2n9Meta?.name ?? "wishlist",
    path: wishlistWWXfInC2n9Meta?.path ?? "/wishlist",
    children: [],
    meta: wishlistWWXfInC2n9Meta || {},
    alias: wishlistWWXfInC2n9Meta?.alias || [],
    redirect: wishlistWWXfInC2n9Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/wishlist.vue").then(m => m.default || m)
  },
  {
    name: xposure_45playlistsM2tx1wx64UMeta?.name ?? "xposure-playlists",
    path: xposure_45playlistsM2tx1wx64UMeta?.path ?? "/xposure-playlists",
    children: [],
    meta: xposure_45playlistsM2tx1wx64UMeta || {},
    alias: xposure_45playlistsM2tx1wx64UMeta?.alias || [],
    redirect: xposure_45playlistsM2tx1wx64UMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/xposure-playlists.vue").then(m => m.default || m)
  }
]