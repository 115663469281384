import plugin_vue3_A0OWXRrUgq from "/opt/atlassian/pipelines/agent/build/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/opt/atlassian/pipelines/agent/build/.nuxt/components.plugin.mjs";
import vueuse_head_plugin_D7WGfuP1A0 from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/head/runtime/lib/vueuse-head.plugin.mjs";
import router_Pg0DINazwm from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/pages/runtime/plugins/router.mjs";
import prefetch_client_3cwHvxIDEX from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.mjs";
import plugin_RdadCF4ArJ from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt-aos/dist/runtime/plugin.mjs";
import plugin_t2GMTTFnMT from "/opt/atlassian/pipelines/agent/build/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import chunk_reload_client_NfcM908xUJ from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/app/plugins/chunk-reload.client.mjs";
import packages_client_Ue0QHu07RS from "/opt/atlassian/pipelines/agent/build/plugins/packages.client.ts";
import main_6QiDOFajJD from "/opt/atlassian/pipelines/agent/build/plugins/main.ts";
import plugin_1UohGbtF8v from "/opt/atlassian/pipelines/agent/build/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.mjs";
export default [
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  vueuse_head_plugin_D7WGfuP1A0,
  router_Pg0DINazwm,
  prefetch_client_3cwHvxIDEX,
  plugin_RdadCF4ArJ,
  plugin_t2GMTTFnMT,
  chunk_reload_client_NfcM908xUJ,
  packages_client_Ue0QHu07RS,
  main_6QiDOFajJD,
  plugin_1UohGbtF8v
]